<template>
  <div>
    <div class="row mb-4">
      <div class="col-4">
        <ConsumptionCard
          :value="emissionScopeBreakdown['1'] / 1000"
          :prev-value="emissionScopeBreakdown['1-prev'] / 1000"
          precision="2"
          icon-class="fa-flame"
          description="Scope 1"
          units="t CO2e"
          :comparison-period="options.comparePeriod"
          class="bg-secondary-outline flex-grow-1"
          :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
          :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
          :error="errorAction.getAnalytics['asset-report-emissions']"
          is-report
        />
      </div>
      <div class="col-4">
        <ConsumptionCard
          :value="emissionScopeBreakdown['2'] / 1000"
          :prev-value="emissionScopeBreakdown['2-prev'] / 1000"
          precision="2"
          icon-class="fa-plug"
          description="Scope 2"
          units="t CO2e"
          :comparison-period="options.comparePeriod"
          class="bg-secondary-outline flex-grow-1"
          :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
          :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
          :error="errorAction.getAnalytics['asset-report-emissions']"
          is-report
        />
      </div>
      <div class="col-4">
        <ConsumptionCard
          :value="emissionScopeBreakdown['3'] / 1000"
          :prev-value="emissionScopeBreakdown['3-prev'] / 1000"
          precision="2"
          icon-class="fa-conveyor-belt-boxes"
          description="Scope 3"
          units="t CO2e"
          :comparison-period="options.comparePeriod"
          class="bg-secondary-outline flex-grow-1"
          :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
          :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
          :error="errorAction.getAnalytics['asset-report-emissions']"
          is-report
        />
      </div>
    </div>
    <div v-if="emissionTypeBreakdown()" class="row mb-4">
      <div v-for="type in types" :key="type" :class="`col-${12 / types.length}`">
        <ConsumptionCard
          :value="emissionTypeBreakdown()?.[type] / 1000"
          :prev-value="emissionTypeBreakdown(true)?.[type] / 1000"
          precision="2"
          :icon-class="typeIcon(type)"
          :description="capitalize(type)"
          units="t CO2e"
          :comparison-period="options.comparePeriod"
          class="bg-primary flex-grow-1"
          :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
          :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
          :error="errorAction.getAnalytics['asset-report-emissions']"
          is-report
        />
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-6">
        <ConsumptionCard
          :value="
            analytics['asset-report-emissions'] &&
            analytics['asset-report-emissions'].results &&
            analytics['asset-report-emissions'].results.total / 1000
          "
          :prev-value="
            analytics['asset-report-emissions-prev'] &&
            analytics['asset-report-emissions-prev'].results &&
            analytics['asset-report-emissions-prev'].results.total / 1000
          "
          icon-class="fa-leaf text-success"
          description="Total Emissions"
          units="t CO2e"
          :comparison-period="options.comparePeriod"
          class="flex-grow-1 bg-secondary"
          :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
          :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
          :error="errorAction.getAnalytics['asset-report-emissions']"
          is-report
        />
      </div>
    </div>
    <div class="text-center">
      <h3>Scope Breakdowns</h3>
    </div>
    <div class="row">
      <div class="col-6">
        <ChartCard :loading="loadingAction.getAnalytics['asset-report-emissions']" :error="errorAction.getAnalytics['asset-report-emissions']">
          <template>
            <div v-if="analytics[`asset-report-emissions`] && analytics[`asset-report-emissions`].results">
              <apexchart height="350" :options="scopeBreakdownChart.options" :series="scopeBreakdownChart.series" />
              <div class="mb-0 mt-4 text-muted"><strong>Source:</strong> {{ emissionSources.join(', ') }}</div>
            </div>
          </template>
        </ChartCard>
      </div>
      <div class="col-6">
        <ChartCard :loading="loadingAction.getAnalytics['asset-report-emissions']" :error="errorAction.getAnalytics['asset-report-emissions']">
          <template>
            <div v-if="analytics[`asset-report-emissions`] && analytics[`asset-report-emissions`].results">
              <apexchart height="350" :options="emissionsBreakdownChart.options" :series="emissionsBreakdownChart.series" />
              <div class="mb-0 mt-4 text-muted">&nbsp;</div>
            </div>
          </template>
        </ChartCard>
      </div>
    </div>
  </div>
</template>
<script>
import ChartCard from '@/components/analytics/ChartCard';
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import { capitalize } from '@/lib/helpers';

export default {
  name: 'EmissionsPage',
  components: {
    ChartCard,
    ConsumptionCard
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Object,
      required: true
    },
    errorAction: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  computed: {
    emissionScopeBreakdown() {
      const scopes = {
        1: 0,
        2: 0,
        3: 0,
        '1-prev': 0,
        '2-prev': 0,
        '3-prev': 0
      };

      (this.analytics['asset-report-emissions']?.results?.breakdown || []).forEach(item => {
        scopes[item.scope] += item.total;
      });

      (this.analytics['asset-report-emissions-prev']?.results?.breakdown || []).forEach(item => {
        scopes[`${item.scope}-prev`] += item.total;
      });

      return scopes;
    },
    emissionsBreakdownChart() {
      const values = (this.analytics['asset-report-emissions']?.results?.breakdown || []).map(item => item.total);

      const isShowMeterUser = this.analytics['asset-report-emissions']?.results?.breakdown.some(item => item.meterUser === 'tenant');

      return {
        series: values,
        options: {
          chart: {
            type: 'pie'
          },
          title: this.chartOptions.title,
          colors: this.chartOptions.colors,
          labels: (this.analytics['asset-report-emissions']?.results?.breakdown || []).map(
            item => `${item.category}${isShowMeterUser ? ` (${capitalize(item.meterUser)})` : ''}`
          ),
          legend: {
            position: 'bottom',
            fontSize: '14px'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    scopeBreakdownChart() {
      const scope1 = this.emissionScopeBreakdown['1'];
      const scope2 = this.emissionScopeBreakdown['2'];
      const scope3 = this.emissionScopeBreakdown['3'];

      return {
        series: [scope1, scope2, scope3],
        options: {
          chart: {
            type: 'pie'
          },
          title: this.chartOptions.title,
          colors: this.chartOptions.colors,
          labels: ['Scope 1', 'Scope 2', 'Scope 3'],
          legend: {
            position: 'bottom',
            fontSize: '14px'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    emissionSources() {
      const emissionSourceMap = {};

      (this.analytics['asset-report-emissions']?.results?.breakdown || []).forEach(item => {
        emissionSourceMap[item.source] = null;
      });

      return Object.keys(emissionSourceMap);
    }
  },
  methods: {
    emissionTypeBreakdown(isPrev = false) {
      const id = isPrev ? 'asset-report-emissions-prev' : 'asset-report-emissions';

      if (!this.analytics[id]?.results?.accountBreakdown) return null;

      const totalsByType = this.types.reduce((acc, type) => {
        const accounts = this.analytics[id]?.results?.accountBreakdown.filter(a => a.account.type === type);
        const totalEmissions = accounts.reduce((acc, account) => acc + account.total, 0);

        acc[type] = totalEmissions;

        return acc;
      }, {});

      return totalsByType;
    },
    typeIcon(type) {
      switch (type) {
        case 'gas':
          return 'fas fa-flame';
        case 'electricity':
          return 'fas fa-bolt';
        case 'water':
          return 'fas fa-tint';
        case 'solar':
          return 'fas fa-solar-panel';
      }
    },
    capitalize
  }
};
</script>
