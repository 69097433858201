var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Ratings Count")]), _c('p', [_vm._v(" The number of active EPC certificates against " + _vm._s(_vm.asset.siteName) + " between " + _vm._s(_vm.period.startDate.format('Do MMM YYYY')) + " and " + _vm._s(_vm.period.endDate.format('Do MMM YYYY')) + ". ")]), _vm.epcRatings ? _c('apexchart', {
    attrs: {
      "height": "300",
      "options": _vm.epcRatingChart.options,
      "series": _vm.epcRatingChart.series
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }