var render = function () {
  var _vm$dataQualityResult, _vm$dataQualityResult2, _vm$dataQualityResult3, _vm$dataQualityResult4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Page', [_c('h2', [_vm._v("Data Quality")]), _c('h3', [_vm._v("Invoices")]), _c('p', [_vm._v("A summary of the quality of invoice consumption data that Etainabl holds for this asset.")]), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-4 pr-4"
  }, [_vm.dataQualityResults.curr.coverage ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": ((_vm$dataQualityResult = _vm.dataQualityResults.curr) === null || _vm$dataQualityResult === void 0 ? void 0 : (_vm$dataQualityResult2 = _vm$dataQualityResult.coverage) === null || _vm$dataQualityResult2 === void 0 ? void 0 : _vm$dataQualityResult2.invoiceCoverage) === -1 ? 0 : _vm.dataQualityResults.curr.coverage.invoiceCoverage,
      "prev-value": ((_vm$dataQualityResult3 = _vm.dataQualityResults.prev) === null || _vm$dataQualityResult3 === void 0 ? void 0 : (_vm$dataQualityResult4 = _vm$dataQualityResult3.coverage) === null || _vm$dataQualityResult4 === void 0 ? void 0 : _vm$dataQualityResult4.invoiceCoverage) === -1 ? 0 : _vm.dataQualityResults.curr.coverage.invoiceCoverage,
      "precision": "2",
      "icon-class": "fa-file-invoice",
      "description": "Invoice Coverage",
      "units": "%",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['data-quality'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['data-quality-prev'],
      "error": _vm.errorAction.getAnalytics['data-quality'],
      "is-report": "",
      "inverse": ""
    }
  }) : _vm._e()], 1)]), _c('div', [_c('h3', [_vm._v("Invoice coverage by account")]), _c('p', [_vm._v("The coverage values below are derived from the number of days during the report period where an invoice exists for an account.")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Account Name")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("MPAN")]), _c('th', [_vm._v("Coverage")])])]), _vm.dataQualityResults.curr.coverageByAccount ? _c('tbody', _vm._l(_vm.dataQualityResults.curr.coverageByAccount.slice(0, 15), function (item) {
    return _c('tr', {
      key: item.account._id
    }, [_c('th', [_vm._v(_vm._s(item.account.name))]), _c('td', [_vm._v(_vm._s(_vm._f("capitalize")(item.account.type)))]), _c('td', [_vm._v(_vm._s(item.account.meterPointNumber))]), _c('td', [item.invoiceCoverage === 100 ? _c('span', {
      staticClass: "text-success font-w600"
    }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(item.invoiceCoverage, 2)) + "% "), _c('i', {
      staticClass: "fa fa-circle-check"
    })]) : item.invoiceCoverage > -1 ? _c('span', {
      staticClass: "text-warning font-w600"
    }, [_vm._v(_vm._s(_vm._f("numberFormat")(item.invoiceCoverage, 2)) + "%")]) : _c('span', {
      staticClass: "text-muted font-w600"
    }, [_vm._v("N/A (Inactive)")])])]);
  }), 0) : _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("No accounts found")])])])])])]), _vm._l(_vm.accountPages, function (page, index) {
    return _c('Page', {
      key: index
    }, [_c('table', {
      staticClass: "table"
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Account Name")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("MPAN")]), _c('th', [_vm._v("Coverage")])])]), _c('tbody', _vm._l(page, function (item) {
      return _c('tr', {
        key: item.account._id
      }, [_c('th', [_vm._v(_vm._s(item.account.name))]), _c('td', [_vm._v(_vm._s(_vm._f("capitalize")(item.account.type)))]), _c('td', [_vm._v(_vm._s(item.account.meterPointNumber))]), _c('td', [item.invoiceCoverage === 100 ? _c('span', {
        staticClass: "text-success font-w600"
      }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(item.invoiceCoverage, 2)) + "% "), _c('i', {
        staticClass: "fa fa-circle-check"
      })]) : item.invoiceCoverage > -1 ? _c('span', {
        staticClass: "text-warning font-w600"
      }, [_vm._v(_vm._s(_vm._f("numberFormat")(item.invoiceCoverage, 2)) + "%")]) : _c('span', {
        staticClass: "text-muted font-w600"
      }, [_vm._v("N/A (Inactive)")])])]);
    }), 0)])]);
  }), _vm.dataQualityResults.curr.coverageByAccount ? _c('Page', [_c('div', [_c('h3', [_vm._v("Invoice coverage by month")]), _c('p', [_vm._v("The coverage values below are derived from the number of days where an invoice exists.")]), _vm.coverageChart ? _c('apexchart', {
    attrs: {
      "height": "600",
      "options": _vm.coverageChart.options,
      "series": _vm.coverageChart.series
    }
  }) : _vm._e(), _c('h3', [_vm._v("Floor Area")]), _c('p', [_vm._v(" Floor area can often be difficult to source but having a floor area values set will allow you to accurately benchmark the asset's emissions with like-for-like assets. Check the Asset Data Quality excel report for detailed information. ")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "45%"
    }
  }, [_vm._v("Item")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Result")]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("Notes")])])]), _c('tbody', [_c('tr', [_c('td', [_c('strong', [_vm._v("Asset - Exists")]), _c('br'), _c('span', [_vm._v("Is there a floor area value set against the asset?")])]), _c('td', [_vm.asset.floorArea > 0 ? _c('span', {
    staticClass: "text-success font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-check"
  }), _vm._v(" PASS")]) : _c('span', {
    staticClass: "text-danger font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-xmark"
  }), _vm._v(" FAIL")])]), _c('td', [_vm.asset.floorArea > 0 ? _c('span', [_vm._v("This asset has a floor area set.")]) : _vm.asset.floorArea === 0 ? _c('span', [_vm._v(" This asset has a floor area of 0. ")]) : _c('span', [_vm._v(" This asset is missing a floor area. ")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Asset - Accuracy")]), _c('br'), _c('span', [_vm._v("Does the floor area value appear accurate?")])]), _c('td', [_vm.asset.floorArea > 1 ? _c('span', {
    staticClass: "text-success font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-check"
  }), _vm._v(" PASS")]) : _c('span', {
    staticClass: "text-danger font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-xmark"
  }), _vm._v(" FAIL")])]), _c('td', [_vm.asset.floorArea > 1 ? _c('span', [_vm._v("Floor area of "), _c('strong', [_vm._v(_vm._s(_vm.asset.floorArea) + " " + _vm._s(_vm._f("floorAreaUnit")(_vm.asset.floorAreaUnit)))]), _vm._v(" is likely accurate.")]) : _vm.asset.floorArea === 1 ? _c('span', [_vm._v(" Floor area of " + _vm._s(_vm.asset.floorArea) + " " + _vm._s(_vm._f("floorAreaUnit")(_vm.asset.floorAreaUnit)) + ", this is likely incorrect. ")]) : _c('span', [_vm._v(" This asset is missing a floor area. ")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Accounts - Exist")]), _c('br'), _c('span', [_vm._v("Is there a floor area value set on all accounts?")])]), _c('td', [_vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea > 0;
  }) ? _c('span', {
    staticClass: "text-success font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-check"
  }), _vm._v(" PASS")]) : _c('span', {
    staticClass: "text-danger font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-xmark"
  }), _vm._v(" FAIL")])]), _c('td', [_vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea > 0;
  }) ? _c('span', [_vm._v("All accounts have a floor area set.")]) : _vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea === 0;
  }) ? _c('span', [_vm._v("No account has a floor area set.")]) : _c('span', [_vm._v("Some accounts are missing a floor area. More details in the Asset Data Quality excel report.")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Accounts - Accuracy")]), _c('br'), _c('span', [_vm._v("Does the account floor area values appear accurate?")])]), _c('td', [_vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea > 1;
  }) ? _c('span', {
    staticClass: "text-success font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-check"
  }), _vm._v(" PASS")]) : _c('span', {
    staticClass: "text-danger font-w600"
  }, [_c('i', {
    staticClass: "fa fa-circle-xmark"
  }), _vm._v(" FAIL")])]), _c('td', [_vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea > 1;
  }) ? _c('span', [_vm._v("All accounts likely have accurate floor areas.")]) : _vm.dataQualityResults.curr.coverageByAccount.every(function (a) {
    return a.floorArea <= 1;
  }) ? _c('span', [_vm._v("All accounts have inaccurate floor areas (e.g. 1 m"), _c('sup', [_vm._v("2")]), _vm._v("). More details in the Asset Data Quality excel report.")]) : _c('span', [_vm._v("Some accounts have inaccurate floor areas (e.g. 1 m"), _c('sup', [_vm._v("2")]), _vm._v("). More details in the Asset Data Quality excel report.")])])])])])], 1)]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }