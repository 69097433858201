var render = function () {
  var _vm$satelliteImages;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.asset.address && _vm.asset.address.latitude ? _c('GoogleMap', {
    staticClass: "mb-4",
    attrs: {
      "height": "300",
      "map-config": _vm.assetMapOptions,
      "street-view-config": _vm.assetStreetViewOptions,
      "is-static": true
    }
  }) : _vm._e(), ((_vm$satelliteImages = _vm.satelliteImages) === null || _vm$satelliteImages === void 0 ? void 0 : _vm$satelliteImages.length) > 0 ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.satelliteImages.filter(function (img) {
    return img.code.includes('birdsEye');
  }), function (image) {
    return _c('div', {
      key: image.code,
      staticClass: "col-6 mb-4"
    }, [_c('img', {
      staticClass: "satellite-image",
      attrs: {
        "src": image.url
      }
    })]);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }