<template>
  <div v-if="!loading && !error">
    <div v-if="title || $slots['title']" class="row align-items-start mb-2">
      <div class="col-md-4">
        <h3 class="mb-0">
          <slot name="title">{{ title }}</slot>
        </h3>
      </div>
      <div class="col text-right">
        <slot name="actions"></slot>
      </div>
    </div>
    <p v-if="description || $slots['description']" class="mb-5">
      <slot name="description">{{ description }}</slot>
    </p>
    <slot></slot>
  </div>

  <div v-else-if="loading && !error" class="d-flex justify-content-center">
    <div class="text-center">
      <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
        <span class="sr-only">Loading data...</span>
      </div>
      <div class="font-w600">Loading data...</div>
    </div>
  </div>
  <div v-else>
    <div class="alert alert-danger">There was an error loading this chart.</div>
  </div>
</template>
<script>
export default {
  name: 'ChartCard',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    error: {
      type: [Boolean, String],
      default: ''
    }
  }
};
</script>
