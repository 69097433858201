<template>
  <div>
    <Page>
      <h2>Data Quality</h2>
      <h3>Invoices</h3>
      <p>A summary of the quality of invoice consumption data that Etainabl holds for this asset.</p>
      <div class="row mb-5">
        <div class="col-4 pr-4">
          <ConsumptionCard
            v-if="dataQualityResults.curr.coverage"
            :value="dataQualityResults.curr?.coverage?.invoiceCoverage === -1 ? 0 : dataQualityResults.curr.coverage.invoiceCoverage"
            :prev-value="dataQualityResults.prev?.coverage?.invoiceCoverage === -1 ? 0 : dataQualityResults.curr.coverage.invoiceCoverage"
            precision="2"
            icon-class="fa-file-invoice"
            description="Invoice Coverage"
            units="%"
            comparison-period="period"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['data-quality']"
            :prev-value-loading="loadingAction.getAnalytics['data-quality-prev']"
            :error="errorAction.getAnalytics['data-quality']"
            is-report
            inverse
          />
        </div>
      </div>
      <div>
        <h3>Invoice coverage by account</h3>
        <p>The coverage values below are derived from the number of days during the report period where an invoice exists for an account.</p>

        <table class="table">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Type</th>
              <th>MPAN</th>
              <th>Coverage</th>
            </tr>
          </thead>
          <tbody v-if="dataQualityResults.curr.coverageByAccount">
            <tr v-for="item in dataQualityResults.curr.coverageByAccount.slice(0, 15)" :key="item.account._id">
              <th>{{ item.account.name }}</th>
              <td>{{ item.account.type | capitalize }}</td>
              <td>{{ item.account.meterPointNumber }}</td>
              <td>
                <span v-if="item.invoiceCoverage === 100" class="text-success font-w600">
                  {{ item.invoiceCoverage | numberFormat(2) }}%
                  <i class="fa fa-circle-check"></i>
                </span>
                <span v-else-if="item.invoiceCoverage > -1" class="text-warning font-w600">{{ item.invoiceCoverage | numberFormat(2) }}%</span>
                <span v-else class="text-muted font-w600">N/A (Inactive)</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">No accounts found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Page>
    <Page v-for="(page, index) in accountPages" :key="index">
      <table class="table">
        <thead>
          <tr>
            <th>Account Name</th>
            <th>Type</th>
            <th>MPAN</th>
            <th>Coverage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in page" :key="item.account._id">
            <th>{{ item.account.name }}</th>
            <td>{{ item.account.type | capitalize }}</td>
            <td>{{ item.account.meterPointNumber }}</td>
            <td>
              <span v-if="item.invoiceCoverage === 100" class="text-success font-w600">
                {{ item.invoiceCoverage | numberFormat(2) }}%
                <i class="fa fa-circle-check"></i>
              </span>
              <span v-else-if="item.invoiceCoverage > -1" class="text-warning font-w600">{{ item.invoiceCoverage | numberFormat(2) }}%</span>
              <span v-else class="text-muted font-w600">N/A (Inactive)</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Page>
    <Page v-if="dataQualityResults.curr.coverageByAccount">
      <div>
        <h3>Invoice coverage by month</h3>
        <p>The coverage values below are derived from the number of days where an invoice exists.</p>
        <apexchart v-if="coverageChart" height="600" :options="coverageChart.options" :series="coverageChart.series" />

        <h3>Floor Area</h3>
        <p>
          Floor area can often be difficult to source but having a floor area values set will allow you to accurately benchmark the asset's emissions
          with like-for-like assets. Check the Asset Data Quality excel report for detailed information.
        </p>
        <table class="table">
          <thead>
            <tr>
              <th style="width: 45%">Item</th>
              <th style="width: 15%">Result</th>
              <th style="width: 40%">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Asset - Exists</strong><br />
                <span>Is there a floor area value set against the asset?</span>
              </td>
              <td>
                <span v-if="asset.floorArea > 0" class="text-success font-w600"><i class="fa fa-circle-check"></i> PASS</span>
                <span v-else class="text-danger font-w600"><i class="fa fa-circle-xmark"></i> FAIL</span>
              </td>
              <td>
                <span v-if="asset.floorArea > 0">This asset has a floor area set.</span>
                <span v-else-if="asset.floorArea === 0"> This asset has a floor area of 0. </span>
                <span v-else> This asset is missing a floor area. </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Asset - Accuracy</strong><br />
                <span>Does the floor area value appear accurate?</span>
              </td>
              <td>
                <span v-if="asset.floorArea > 1" class="text-success font-w600"><i class="fa fa-circle-check"></i> PASS</span>
                <span v-else class="text-danger font-w600"><i class="fa fa-circle-xmark"></i> FAIL</span>
              </td>
              <td>
                <span v-if="asset.floorArea > 1"
                  >Floor area of <strong>{{ asset.floorArea }} {{ asset.floorAreaUnit | floorAreaUnit }}</strong> is likely accurate.</span
                >
                <span v-else-if="asset.floorArea === 1">
                  Floor area of {{ asset.floorArea }} {{ asset.floorAreaUnit | floorAreaUnit }}, this is likely incorrect.
                </span>
                <span v-else> This asset is missing a floor area. </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Accounts - Exist</strong><br />
                <span>Is there a floor area value set on all accounts?</span>
              </td>
              <td>
                <span v-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea > 0)" class="text-success font-w600"
                  ><i class="fa fa-circle-check"></i> PASS</span
                >
                <span v-else class="text-danger font-w600"><i class="fa fa-circle-xmark"></i> FAIL</span>
              </td>
              <td>
                <span v-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea > 0)">All accounts have a floor area set.</span>
                <span v-else-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea === 0)">No account has a floor area set.</span>
                <span v-else>Some accounts are missing a floor area. More details in the Asset Data Quality excel report.</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Accounts - Accuracy</strong><br />
                <span>Does the account floor area values appear accurate?</span>
              </td>
              <td>
                <span v-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea > 1)" class="text-success font-w600"
                  ><i class="fa fa-circle-check"></i> PASS</span
                >
                <span v-else class="text-danger font-w600"><i class="fa fa-circle-xmark"></i> FAIL</span>
              </td>
              <td>
                <span v-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea > 1)"
                  >All accounts likely have accurate floor areas.</span
                >
                <span v-else-if="dataQualityResults.curr.coverageByAccount.every(a => a.floorArea <= 1)"
                  >All accounts have inaccurate floor areas (e.g. 1 m<sup>2</sup>). More details in the Asset Data Quality excel report.</span
                >
                <span v-else
                  >Some accounts have inaccurate floor areas (e.g. 1 m<sup>2</sup>). More details in the Asset Data Quality excel report.</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Page>
  </div>
</template>
<script>
import moment from 'moment';
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import Page from './Page';

export default {
  name: 'DataQualityPages',
  components: {
    ConsumptionCard,
    Page
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Object,
      required: true
    },
    errorAction: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accountPages: []
    };
  },
  computed: {
    dataQualityResults() {
      if (!this.analytics['data-quality']) {
        return { curr: {}, prev: {} };
      }

      return {
        curr: this.dataQualitySummary(),
        prev: this.dataQualitySummaryPrev()
      };
    },
    coverageChart() {
      return {
        series: [
          {
            data: this.dataQualityResults.curr.coverageByMonth
              ? Object.keys(this.dataQualityResults.curr.coverageByMonth).map(
                  month => this.dataQualityResults.curr.coverageByMonth[month]?.invoiceCoverage
                )
              : []
          }
        ],
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: 0
          },
          colors: [
            '#65C198',
            '#1F303D',
            '#F25F5C',
            '#FFE066',
            '#1B98E0',
            '#727272',
            '#db6edc',
            '#945ab9',
            '#25714e',
            '#941d1c',
            '#977911',
            '#045889'
          ],
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            categories: Object.keys(this.dataQualityResults.curr.coverageByMonth || {}).map(d => moment(d).format('MMM YYYY')),
            labels: {
              style: {
                fontSize: '18px',
                fontWeight: 600
              },
              offsetY: 10,
              show: false
            },
            min: 0,
            max: 100
          },
          grid: {
            show: false
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '16px',
                fontWeight: 600
              },
              offsetY: 3
            }
          },
          dataLabels: {
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          }
        }
      };
    }
  },
  methods: {
    dataQualitySummary() {
      const id = 'data-quality';

      if (!this.analytics[id]) return {};

      const { results } = this.analytics[id];

      if (!results) return {};

      let coverageByAccount = results.map(account => {
        const totalDays = account.totals.activeDays + account.totals.inactiveDays;

        const coverages = {
          inactive: (account.totals.inactiveDays / totalDays) * 100,
          active: (account.totals.activeDays / totalDays) * 100,
          invoiceCoverage: account.totals.activeDays > 0 ? (account.totals.invoiceDays / account.totals.activeDays) * 100 : -1
        };

        return {
          account,
          ...coverages
        };
      });

      coverageByAccount.sort((a, b) => b.invoiceCoverage - a.invoiceCoverage);

      if (coverageByAccount.length > 15) {
        // Add more pages to report
        const additionalCoverageByAccount = coverageByAccount.slice(15); // 15 accounts on first page
        const perPage = 24; // on each additional page

        this.accountPages = additionalCoverageByAccount.reduce((acc, item, index) => {
          if (index % perPage === 0) acc.push(additionalCoverageByAccount.slice(index, index + perPage));
          return acc;
        }, []);
      }

      const coverage = results.reduce(
        (acc, account) => {
          acc.inactiveDaysSum += account.totals.inactiveDays;
          acc.activeDaysSum += account.totals.activeDays;
          acc.invoiceDaysSum += account.totals.invoiceDays;

          acc.invoiceCoverage = acc.activeDaysSum > 0 ? (acc.invoiceDaysSum / acc.activeDaysSum) * 100 : -1;

          return acc;
        },
        {
          inactiveDaysSum: 0,
          activeDaysSum: 0,
          invoiceDaysSum: 0,
          invoiceCoverage: 0
        }
      );

      const coverageByMonth = results.reduce((acc, account) => {
        Object.keys(account.monthlyTotals).forEach(month => {
          if (!acc[month]) {
            acc[month] = {
              inactiveDaysSum: 0,
              activeDaysSum: 0,
              invoiceDaysSum: 0,
              invoiceCoverage: 0
            };
          }
          acc[month].inactiveDaysSum += account.monthlyTotals[month].inactiveDays;
          acc[month].activeDaysSum += account.monthlyTotals[month].activeDays;
          acc[month].invoiceDaysSum += account.monthlyTotals[month].invoiceDays;
          acc[month].invoiceCoverage = acc[month].activeDaysSum > 0 ? (acc[month].invoiceDaysSum / acc[month].activeDaysSum) * 100 : -1;
        });

        return acc;
      }, {});

      return {
        coverage,
        coverageByAccount,
        coverageByMonth
      };
    },

    dataQualitySummaryPrev() {
      const id = 'data-quality-prev';

      if (!this.analytics[id]) return {};

      const { results } = this.analytics[id];

      if (!results) return {};

      let coverageByAccount = results.map(account => {
        const totalDays = account.totals.activeDays + account.totals.inactiveDays;

        const coverages = {
          inactive: (account.totals.inactiveDays / totalDays) * 100,
          active: (account.totals.activeDays / totalDays) * 100,
          invoiceCoverage: account.totals.activeDays > 0 ? (account.totals.invoiceDays / account.totals.activeDays) * 100 : -1
        };

        return {
          account,
          ...coverages
        };
      });

      coverageByAccount.sort((a, b) => b.invoiceCoverage - a.invoiceCoverage);

      if (coverageByAccount.length > 15) {
        // Add more pages to report
        const additionalCoverageByAccount = coverageByAccount.slice(15); // 15 accounts on first page
        const perPage = 24; // on each additional page

        this.accountPages = additionalCoverageByAccount.reduce((acc, item, index) => {
          if (index % perPage === 0) acc.push(additionalCoverageByAccount.slice(index, index + perPage));
          return acc;
        }, []);
      }

      const coverage = results.reduce(
        (acc, account) => {
          acc.inactiveDaysSum += account.totals.inactiveDays;
          acc.activeDaysSum += account.totals.activeDays;
          acc.invoiceDaysSum += account.totals.invoiceDays;

          acc.invoiceCoverage = acc.activeDaysSum > 0 ? (acc.invoiceDaysSum / acc.activeDaysSum) * 100 : -1;

          return acc;
        },
        {
          inactiveDaysSum: 0,
          activeDaysSum: 0,
          invoiceDaysSum: 0,
          invoiceCoverage: 0
        }
      );

      const coverageByMonth = results.reduce((acc, account) => {
        Object.keys(account.monthlyTotals).forEach(month => {
          if (!acc[month]) {
            acc[month] = {
              inactiveDaysSum: 0,
              activeDaysSum: 0,
              invoiceDaysSum: 0,
              invoiceCoverage: 0
            };
          }
          acc[month].inactiveDaysSum += account.monthlyTotals[month].inactiveDays;
          acc[month].activeDaysSum += account.monthlyTotals[month].activeDays;
          acc[month].invoiceDaysSum += account.monthlyTotals[month].invoiceDays;
          acc[month].invoiceCoverage = acc[month].activeDaysSum > 0 ? (acc[month].invoiceDaysSum / acc[month].activeDaysSum) * 100 : -1;
        });

        return acc;
      }, {});

      return {
        coverage,
        coverageByAccount,
        coverageByMonth
      };
    }
  }
};
</script>
