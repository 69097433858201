<template>
  <div>
    <h3>Ratings Count</h3>
    <p>
      The number of active EPC certificates against {{ asset.siteName }} between {{ period.startDate.format('Do MMM YYYY') }} and
      {{ period.endDate.format('Do MMM YYYY') }}.
    </p>
    <apexchart v-if="epcRatings" height="300" :options="epcRatingChart.options" :series="epcRatingChart.series" />
  </div>
</template>
<script>
export default {
  name: 'EPCRatingsPage',
  props: {
    asset: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  },
  computed: {
    epcRatingChart() {
      return {
        series: [
          {
            data: Object.values(this.epcRatings)
          }
        ],
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: 0
          },
          colors: [
            '#65C198',
            '#1F303D',
            '#F25F5C',
            '#FFE066',
            '#1B98E0',
            '#727272',
            '#db6edc',
            '#945ab9',
            '#25714e',
            '#941d1c',
            '#977911',
            '#045889'
          ],
          plotOptions: {},
          xaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              style: {
                fontSize: '16px',
                fontWeight: 600
              },
              offsetY: 3
            },
            categories: Object.keys(this.epcRatings)
          },
          grid: {
            show: false
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '18px',
                fontWeight: 600
              },
              offsetY: 10,
              show: false
            }
          },
          dataLabels: {
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          }
        }
      };
    },
    epcRatings() {
      if (!this.analytics['epc-ratings']) {
        return [];
      }

      const { results } = this.analytics['epc-ratings'];

      return {
        ...results
      };
    }
  }
};
</script>
