<template>
  <div>
    <GoogleMap
      v-if="asset.address && asset.address.latitude"
      class="mb-4"
      height="300"
      :map-config="assetMapOptions"
      :street-view-config="assetStreetViewOptions"
      :is-static="true"
    />

    <div v-if="satelliteImages?.length > 0" class="row">
      <div v-for="image in satelliteImages.filter(img => img.code.includes('birdsEye'))" :key="image.code" class="col-6 mb-4">
        <img class="satellite-image" :src="image.url" />
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMap from '@/components/GoogleMap';

export default {
  name: 'ImagesPage',
  components: {
    GoogleMap
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    satelliteImages: {
      type: Array,
      required: true
    }
  },
  computed: {
    assetMapOptions() {
      return {
        center: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        zoom: 18,
        mapTypeId: 'satellite',
        disableDefaultUI: true
      };
    },
    assetStreetViewOptions() {
      return {
        position: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        pov: {
          heading: this.asset.address.heading || 0,
          pitch: this.asset.address.pitch || 0
        },
        zoom: 0,
        disableDefaultUI: true
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.satellite-image {
  width: 100%;
  object-fit: contain;
}
</style>
