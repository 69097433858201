<template>
  <Pages title="Asset Summary">
    <Page :title="asset.siteName" sub-title="Asset Report" :description="asset.addressString">
      <template v-slot:title>
        <h5 class="text-muted">{{ period.startDate | date('Do MMMM YYYY') }} - {{ period.endDate | date('Do MMMM YYYY') }}</h5>
      </template>
    </Page>
    <Page v-if="report.metadata?.images">
      <h2>Images</h2>
      <p>
        A selection of images of {{ asset.siteName }} from Google and Bing maps. Images may not be entirely accurate, but are used to illustrate the
        location and surrounding area of the asset.
      </p>
      <ImagesPage :asset="asset" :satellite-images="satelliteImages" />
    </Page>
    <Page v-if="report.metadata?.summary">
      <AssetDetailsPage :asset="asset" :accounts="accounts" />
    </Page>
    <Page v-if="report.metadata?.emissions">
      <h2>Emissions</h2>
      <p>
        Below are the Scope 1, 2 and 3 emissions for {{ asset.siteName }}. These are calculated from invoice consumption using BEIS emission factors.
      </p>
      <EmissionsPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        :types="types"
      />
    </Page>
    <Page v-if="report.metadata?.consumptionDetailed && types.includes('electricity')">
      <h2>Electricity Usage</h2>
      <ConsumptionPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        units="kWh"
        type="electricity"
      />
    </Page>
    <Page v-if="report.metadata?.consumptionDetailed && types.includes('gas')">
      <h2>Gas Usage</h2>
      <ConsumptionPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        units="kWh"
        type="gas"
      />
    </Page>
    <Page v-if="report.metadata?.consumptionDetailed && types.includes('water')">
      <h2>Water Usage</h2>
      <ConsumptionPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        units="m3"
        type="water"
      />
    </Page>
    <Page v-if="report.metadata?.consumptionDetailed && types.includes('waste')">
      <h2>Waste Usage</h2>
      <ConsumptionPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        units="kg"
        type="waste"
      />
    </Page>

    <Page v-if="report.metadata?.consumptionDetailed && types.includes('solar')">
      <h2>Solar Usage</h2>
      <ConsumptionPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        units="kWh"
        type="solar"
      />
    </Page>
    <DataQualityPages
      v-if="report.metadata?.dataQuality"
      :asset="asset"
      :period="period"
      :analytics="analytics"
      :loading-action="loadingAction"
      :error-action="errorAction"
      :options="analyticsOptions"
      :chart-options="defaultChartOptions"
    />
    <Page v-if="report.metadata?.notes">
      <h2>Commentary</h2>
      <span style="white-space: pre-line">{{ report.metadata.notes }}</span>
    </Page>
    <Page v-if="report.metadata?.epc">
      <h2>EPC Report</h2>
      <p>More information about the EPC certificates held against this asset including recommendations.</p>
      <EPCRatingsPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
      />
    </Page>
    <ProjectPages
      v-if="report.metadata?.projects"
      :asset="asset"
      :period="period"
      :analytics="analytics"
      :loading-action="loadingAction"
      :error-action="errorAction"
      :options="analyticsOptions"
      :chart-options="defaultChartOptions"
    />
    <Page v-if="report.metadata?.landlordTenant">
      <h2>Landlord/Tenant Information</h2>
      <p>Detailed breakdown of consumption, accounts and any other information relating to landlord and tenant data.</p>
      <LandlordTenantPage
        :asset="asset"
        :period="period"
        :analytics="analytics"
        :loading-action="loadingAction"
        :error-action="errorAction"
        :options="analyticsOptions"
        :chart-options="defaultChartOptions"
        :types="types"
      />
    </Page>
  </Pages>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Page from './components/Page';
import Pages from './components/Pages';
import AssetDetailsPage from './components/AssetDetailsPage';
import DataQualityPages from './components/DataQualityPages';
import EPCRatingsPage from './components/EPCRatingsPage';
import EmissionsPage from './components/EmissionsPage';
import ImagesPage from './components/ImagesPage';
import ProjectPages from './components/ProjectPages';
import LandlordTenantPage from './components/LandlordTenantPage';
import ConsumptionPage from './components/ConsumptionPage.vue';

export default {
  name: 'AssetDetailedPDF',
  components: {
    AssetDetailsPage,
    DataQualityPages,
    EPCRatingsPage,
    EmissionsPage,
    ImagesPage,
    ProjectPages,
    LandlordTenantPage,
    Page,
    Pages,
    ConsumptionPage
  },
  data() {
    return {
      types: ['electricity', 'solar', 'gas', 'water'],
      period: {
        startDate: moment().subtract(1, 'year').startOf('year'),
        endDate: moment().subtract(1, 'year').endOf('year'),
        prevStartDate: moment().subtract(2, 'year').startOf('year'),
        prevEndDate: moment().subtract(2, 'year').endOf('year')
      },
      options: {}
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      report: 'report/report',
      satelliteImages: 'asset/satelliteImages',
      analytics: 'analytics/analytics',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      analyticsOptions: 'analytics/options'
    }),
    defaultChartOptions() {
      return {
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Label'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        colors: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0', '#727272', '#db6edc', '#945ab9', '#25714e', '#941d1c', '#977911', '#045889'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      };
    }
  },
  async mounted() {
    const assetSource = this.report.metadata.sourceIds.find(id => id.type === 'asset');
    if (!assetSource) this.errorRedirect('Missing Asset ID in Report Metadata');

    const assetId = assetSource._id;

    if (!assetId) this.errorRedirect('Missing Asset ID');

    await this.getReport({ id: this.report._id });

    this.period.startDate = moment(this.report.metadata.startDate).startOf('month');
    this.period.endDate = moment(this.report.metadata.endDate).endOf('month');
    this.period.prevStartDate = moment(this.period.startDate).subtract(1, 'year');
    this.period.prevEndDate = moment(this.period.endDate).subtract(1, 'year');

    if (this.report?.metadata.images) this.getSatelliteImages({ id: assetId });
    await this.getAsset({ id: assetId });
    this.getCompany({ id: this.asset.companyId });
    if (this.report?.metadata.summary) this.getAssetAccounts({ id: assetId });
    if (this.report?.metadata.emissions) this.getEmissions();
    if (this.report?.metadata.consumptionDetailed || this.report?.metadata.consumptionSummary) this.getConsumption();
    if (this.report?.metadata.dataQuality) this.getDataQuality();
    if (this.report?.metadata.epc) this.getEPCRatings();
    if (this.report?.metadata.projects) this.getProjects();
    if (this.report?.metadata.landlordTenant) this.getLandlordTenant();
  },
  methods: {
    ...mapActions({
      getAsset: 'asset/get',
      getAssetAccounts: 'asset/accounts',
      getReport: 'report/get',
      getSatelliteImages: 'asset/getSatelliteImages',
      getCompany: 'company/get',
      getAnalytics: 'analytics/getAnalytics'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    },
    getEmissions() {
      const consumptionSummaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        sourceData: 'invoice',
        dateBreakdown: 'monthly',
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId,
        accountBreakdown: true,
        includeAccount: true
      };

      this.getAnalytics({
        id: 'asset-report-emissions',
        params: {
          id: 'asset-emission',
          ...consumptionSummaryParams,
          dateBreakdown: undefined
        }
      });

      this.getAnalytics({
        id: 'asset-report-emissions-prev',
        params: {
          id: 'asset-emission',
          ...consumptionSummaryParams,
          dateBreakdown: undefined,
          startDate: this.period.prevStartDate.format('YYYY-MM-DD'),
          endDate: this.period.prevEndDate.format('YYYY-MM-DD')
        }
      });
    },
    getConsumption() {
      const summaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId
      };

      const consumptionSummaryParams = {
        ...summaryParams,
        sourceData: 'invoice',
        dateBreakdown: 'monthly'
      };

      this.types.forEach(type => {
        this.getAnalytics({
          id: `${type}-consumption`,
          params: {
            id: 'asset-consumption',
            accountType: type,
            estimateBreakdown: true,
            meterUserBreakdown: true,
            ...consumptionSummaryParams
          }
        });

        this.getAnalytics({
          id: `${type}-consumption-prev`,
          params: {
            id: 'asset-consumption',
            accountType: type,
            estimateBreakdown: true,
            meterUserBreakdown: true,
            ...consumptionSummaryParams,
            startDate: this.period.prevStartDate.format('YYYY-MM-DD'),
            endDate: this.period.prevEndDate.format('YYYY-MM-DD')
          }
        });
      });
    },
    getDataQuality() {
      const summaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId
      };

      this.getAnalytics({
        id: 'data-quality',
        params: {
          id: 'asset-data-quality',
          ...summaryParams
        }
      });

      this.getAnalytics({
        id: 'data-quality-prev',
        params: {
          id: 'asset-data-quality',
          ...summaryParams,
          startDate: this.period.prevStartDate.format('YYYY-MM-DD'),
          endDate: this.period.prevEndDate.format('YYYY-MM-DD')
        }
      });
    },
    getEPCRatings() {
      const summaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId
      };

      this.getAnalytics({
        id: 'epc-ratings',
        params: {
          id: 'epc-ratings',
          ...summaryParams
        }
      });
    },
    getProjects() {
      const summaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId
      };

      this.getAnalytics({
        id: 'projects',
        params: {
          id: 'asset-projects',
          ...summaryParams
        }
      });

      this.getAnalytics({
        id: 'projects-prev',
        params: {
          id: 'asset-projects',
          ...summaryParams,
          startDate: this.period.prevStartDate.format('YYYY-MM-DD'),
          endDate: this.period.prevEndDate.format('YYYY-MM-DD')
        }
      });
    },
    getLandlordTenant() {
      const summaryParams = {
        startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
        assetId: this.$route.query.assetId,
        companyId: this.asset.companyId
      };

      this.getAnalytics({
        id: 'landlord-tenant',
        params: {
          id: 'asset-landlord-tenant',
          ...summaryParams
        }
      });

      this.getAnalytics({
        id: 'landlord-tenant-prev',
        params: {
          id: 'asset-landlord-tenant',
          ...summaryParams,
          startDate: this.period.prevStartDate.format('YYYY-MM-DD'),
          endDate: this.period.prevEndDate.format('YYYY-MM-DD')
        }
      });
    }
  }
};
</script>
