var render = function () {
  var _vm$report$metadata, _vm$report$metadata2, _vm$report$metadata3, _vm$report$metadata4, _vm$report$metadata5, _vm$report$metadata6, _vm$report$metadata7, _vm$report$metadata8, _vm$report$metadata9, _vm$report$metadata10, _vm$report$metadata11, _vm$report$metadata12, _vm$report$metadata13;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Pages', {
    attrs: {
      "title": "Asset Summary"
    }
  }, [_c('Page', {
    attrs: {
      "title": _vm.asset.siteName,
      "sub-title": "Asset Report",
      "description": _vm.asset.addressString
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate, 'Do MMMM YYYY')) + " - " + _vm._s(_vm._f("date")(_vm.period.endDate, 'Do MMMM YYYY')))])];
      },
      proxy: true
    }])
  }), (_vm$report$metadata = _vm.report.metadata) !== null && _vm$report$metadata !== void 0 && _vm$report$metadata.images ? _c('Page', [_c('h2', [_vm._v("Images")]), _c('p', [_vm._v(" A selection of images of " + _vm._s(_vm.asset.siteName) + " from Google and Bing maps. Images may not be entirely accurate, but are used to illustrate the location and surrounding area of the asset. ")]), _c('ImagesPage', {
    attrs: {
      "asset": _vm.asset,
      "satellite-images": _vm.satelliteImages
    }
  })], 1) : _vm._e(), (_vm$report$metadata2 = _vm.report.metadata) !== null && _vm$report$metadata2 !== void 0 && _vm$report$metadata2.summary ? _c('Page', [_c('AssetDetailsPage', {
    attrs: {
      "asset": _vm.asset,
      "accounts": _vm.accounts
    }
  })], 1) : _vm._e(), (_vm$report$metadata3 = _vm.report.metadata) !== null && _vm$report$metadata3 !== void 0 && _vm$report$metadata3.emissions ? _c('Page', [_c('h2', [_vm._v("Emissions")]), _c('p', [_vm._v(" Below are the Scope 1, 2 and 3 emissions for " + _vm._s(_vm.asset.siteName) + ". These are calculated from invoice consumption using BEIS emission factors. ")]), _c('EmissionsPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "types": _vm.types
    }
  })], 1) : _vm._e(), (_vm$report$metadata4 = _vm.report.metadata) !== null && _vm$report$metadata4 !== void 0 && _vm$report$metadata4.consumptionDetailed && _vm.types.includes('electricity') ? _c('Page', [_c('h2', [_vm._v("Electricity Usage")]), _c('ConsumptionPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "units": "kWh",
      "type": "electricity"
    }
  })], 1) : _vm._e(), (_vm$report$metadata5 = _vm.report.metadata) !== null && _vm$report$metadata5 !== void 0 && _vm$report$metadata5.consumptionDetailed && _vm.types.includes('gas') ? _c('Page', [_c('h2', [_vm._v("Gas Usage")]), _c('ConsumptionPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "units": "kWh",
      "type": "gas"
    }
  })], 1) : _vm._e(), (_vm$report$metadata6 = _vm.report.metadata) !== null && _vm$report$metadata6 !== void 0 && _vm$report$metadata6.consumptionDetailed && _vm.types.includes('water') ? _c('Page', [_c('h2', [_vm._v("Water Usage")]), _c('ConsumptionPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "units": "m3",
      "type": "water"
    }
  })], 1) : _vm._e(), (_vm$report$metadata7 = _vm.report.metadata) !== null && _vm$report$metadata7 !== void 0 && _vm$report$metadata7.consumptionDetailed && _vm.types.includes('waste') ? _c('Page', [_c('h2', [_vm._v("Waste Usage")]), _c('ConsumptionPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "units": "kg",
      "type": "waste"
    }
  })], 1) : _vm._e(), (_vm$report$metadata8 = _vm.report.metadata) !== null && _vm$report$metadata8 !== void 0 && _vm$report$metadata8.consumptionDetailed && _vm.types.includes('solar') ? _c('Page', [_c('h2', [_vm._v("Solar Usage")]), _c('ConsumptionPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "units": "kWh",
      "type": "solar"
    }
  })], 1) : _vm._e(), (_vm$report$metadata9 = _vm.report.metadata) !== null && _vm$report$metadata9 !== void 0 && _vm$report$metadata9.dataQuality ? _c('DataQualityPages', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions
    }
  }) : _vm._e(), (_vm$report$metadata10 = _vm.report.metadata) !== null && _vm$report$metadata10 !== void 0 && _vm$report$metadata10.notes ? _c('Page', [_c('h2', [_vm._v("Commentary")]), _c('span', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(_vm._s(_vm.report.metadata.notes))])]) : _vm._e(), (_vm$report$metadata11 = _vm.report.metadata) !== null && _vm$report$metadata11 !== void 0 && _vm$report$metadata11.epc ? _c('Page', [_c('h2', [_vm._v("EPC Report")]), _c('p', [_vm._v("More information about the EPC certificates held against this asset including recommendations.")]), _c('EPCRatingsPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions
    }
  })], 1) : _vm._e(), (_vm$report$metadata12 = _vm.report.metadata) !== null && _vm$report$metadata12 !== void 0 && _vm$report$metadata12.projects ? _c('ProjectPages', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions
    }
  }) : _vm._e(), (_vm$report$metadata13 = _vm.report.metadata) !== null && _vm$report$metadata13 !== void 0 && _vm$report$metadata13.landlordTenant ? _c('Page', [_c('h2', [_vm._v("Landlord/Tenant Information")]), _c('p', [_vm._v("Detailed breakdown of consumption, accounts and any other information relating to landlord and tenant data.")]), _c('LandlordTenantPage', {
    attrs: {
      "asset": _vm.asset,
      "period": _vm.period,
      "analytics": _vm.analytics,
      "loading-action": _vm.loadingAction,
      "error-action": _vm.errorAction,
      "options": _vm.analyticsOptions,
      "chart-options": _vm.defaultChartOptions,
      "types": _vm.types
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }