var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-5"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.type)) + " consumption for the period " + _vm._s(_vm.period.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.period.endDate.format('Do MMM YYYY')) + " ")]), _vm.consumptionChart ? _c('apexchart', {
    attrs: {
      "height": "300",
      "options": _vm.consumptionChart.options,
      "series": _vm.consumptionChart.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 pr-4"
  }, [_c('h3', [_vm._v("Landlord vs Tenant")]), _vm.tenantChart ? _c('apexchart', {
    attrs: {
      "height": "100",
      "options": _vm.tenantChart.summaryOptions,
      "series": _vm.tenantChart.summarySeries
    }
  }) : _vm._e(), _vm.tenantChart ? _c('apexchart', {
    attrs: {
      "height": "250",
      "options": _vm.tenantChart.options,
      "series": _vm.tenantChart.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-6 pl-4"
  }, [_c('h3', [_vm._v("Actual vs Estimated")]), _vm.estimateChart ? _c('apexchart', {
    attrs: {
      "height": "100",
      "options": _vm.estimateChart.summaryOptions,
      "series": _vm.estimateChart.summarySeries
    }
  }) : _vm._e(), _vm.estimateChart ? _c('apexchart', {
    attrs: {
      "height": "250",
      "options": _vm.estimateChart.options,
      "series": _vm.estimateChart.series
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }