<template>
  <div>
    <h3>Floor Areas</h3>
    <p>
      Distribution of floor areas between landlord and tenant accounts (not including sub-meters). Any accounts that do not have a valid floor area
      have been excluded from the calculations.
    </p>
    <div class="row mb-5">
      <div class="col-4">
        <ConsumptionCard
          v-if="landlordTenantData"
          :value="landlordTenantData.totals.landlord"
          precision="0"
          icon-class="fa-crown"
          description="Landlord"
          units="m2"
          comparison-period="period"
          class="flex-grow-1 bg-secondary"
          :value-loading="loadingAction.getAnalytics['landlord-tenant']"
          :error="errorAction.getAnalytics['landlord-tenant']"
          is-report
          inverse-color
          hide-prev-value
        />
      </div>
      <div class="col-4">
        <ConsumptionCard
          v-if="landlordTenantData"
          :value="landlordTenantData.totals.tenant"
          precision="0"
          icon-class="fa-house-user"
          description="Tenant"
          units="m2"
          comparison-period="period"
          class="flex-grow-1"
          :value-loading="loadingAction.getAnalytics['landlord-tenant']"
          :error="errorAction.getAnalytics['landlord-tenant']"
          is-report
          inverse-color
          hide-prev-value
        />
      </div>
      <div class="col-4">
        <ConsumptionCard
          v-if="landlordTenantData"
          :value="landlordTenantData.totals.total"
          precision="0"
          icon-class="fa-square-dashed"
          description="Total"
          units="m2"
          comparison-period="period"
          class="flex-grow-1 bg-info"
          :value-loading="loadingAction.getAnalytics['landlord-tenant']"
          :error="errorAction.getAnalytics['landlord-tenant']"
          is-report
          inverse-color
          hide-prev-value
        />
      </div>
    </div>
    <div class="mb-5">
      <h3 class="mb-0">Landlord/Tenant Split</h3>
      <apexchart v-if="floorAreaChart" height="100" :options="floorAreaChart.options" :series="floorAreaChart.series" />

      <div class="row">
        <div v-for="supplyType in types" :key="supplyType" class="col-6">
          <h4 class="mb-2">{{ supplyType | capitalize }} Consumption</h4>
          <p v-if="consumptionChart(supplyType)" class="mb-0">
            <strong>Landlord:</strong> {{ consumptionChart(supplyType).series[0].data[0] | utilityUnit(supplyType) }}
          </p>
          <p v-if="consumptionChart(supplyType)" class="mb-0">
            <strong>Tenant:</strong> {{ consumptionChart(supplyType).series[1].data[0] | utilityUnit(supplyType) }}
          </p>
          <apexchart
            v-if="consumptionChart(supplyType)"
            height="100"
            :options="consumptionChart(supplyType).options"
            :series="consumptionChart(supplyType).series"
          />
          <div v-else class="mt-2 font-w600 text-muted">No {{ supplyType }} data found</div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <h3>Carbon Intensity</h3>
      <p>
        The CO2e m<sup>2</sup> values for landlord and tenant consumption data, taking into account the floor area. Consumption on accounts with no
        floor area has been excluded.
      </p>

      <div class="row mb-5">
        <div class="col-4">
          <ConsumptionCard
            v-if="carbonIntensity()"
            :value="carbonIntensity()?.landlord"
            :prev-value="carbonIntensity(true)?.landlord"
            precision="2"
            icon-class="fa-crown"
            description="Landlord"
            units="CO2e/m2"
            comparison-period="period"
            class="flex-grow-1 bg-secondary"
            :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
            :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
            :error="errorAction.getAnalytics['asset-report-emissions']"
            is-report
          />
        </div>
        <div class="col-4">
          <ConsumptionCard
            v-if="carbonIntensity"
            :value="carbonIntensity()?.tenant"
            :prev-value="carbonIntensity(true)?.tenant"
            precision="2"
            icon-class="fa-house-user"
            description="Tenant"
            units="CO2e/m2"
            comparison-period="period"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
            :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
            :error="errorAction.getAnalytics['asset-report-emissions']"
            is-report
          />
        </div>
        <div class="col-4">
          <ConsumptionCard
            v-if="carbonIntensity"
            :value="carbonIntensity()?.total"
            :prev-value="carbonIntensity(true)?.total"
            precision="2"
            icon-class="fa-square-dashed"
            description="Total"
            units="CO2e/m2"
            comparison-period="period"
            class="flex-grow-1 bg-info"
            :value-loading="loadingAction.getAnalytics['asset-report-emissions']"
            :prev-value-loading="loadingAction.getAnalytics['asset-report-emissions-prev']"
            :error="errorAction.getAnalytics['asset-report-emissions']"
            is-report
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import { floorAreaConversion } from '@/lib/helpers';

export default {
  name: 'LandlordTenantPage',
  components: {
    ConsumptionCard
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Object,
      required: true
    },
    errorAction: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  computed: {
    landlordTenantData(isPrev = false) {
      const id = isPrev ? 'landlord-tenant-prev' : 'landlord-tenant';

      if (!this.analytics[id] || !this.analytics[id].results) return null;

      const { results } = this.analytics[id];

      const totals = {};

      totals.landlord = results
        .filter(a => a.meterUser === 'landlord' && a.floorArea > 1)
        .reduce((sum, a) => sum + floorAreaConversion(a.floorArea, a.floorAreaUnit), 0);

      totals.tenant = results
        .filter(a => a.meterUser === 'tenant' && a.floorArea > 1)
        .reduce((sum, a) => sum + floorAreaConversion(a.floorArea, a.floorAreaUnit), 0);

      totals.total = totals.landlord + totals.tenant;

      return {
        dataByAccount: results,
        totals
      };
    },
    distributionChartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        colors: [this.chartOptions.colors[1], this.chartOptions.colors[0]],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: ['Total'],
          title: false,
          labels: {
            show: false
          },
          axisTicks: false,
          axisBorder: false
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        stroke: {
          show: false
        },
        dataLabels: {
          style: {
            fontWeight: 600,
            fontSize: '18px'
          }
        }
      };
    },
    floorAreaChart() {
      return {
        series: [
          {
            name: 'Landlord',
            data: this.landlordTenantData?.totals?.landlord ? [this.landlordTenantData?.totals?.landlord] : []
          },
          {
            name: 'Tenant',
            data: this.landlordTenantData?.totals?.tenant ? [this.landlordTenantData?.totals?.tenant] : []
          }
        ],
        options: this.distributionChartOptions
      };
    }
  },
  methods: {
    consumptionChart(type = 'electricity') {
      const { tenantConsumption, totalConsumption } = this.analytics[`${type}-consumption`]?.results || {};

      if (!totalConsumption) return null;

      return {
        series: [
          {
            name: 'Landlord',
            data: [(totalConsumption - tenantConsumption).toFixed(2)]
          },
          {
            name: 'Tenant',
            data: [tenantConsumption.toFixed(2)]
          }
        ],
        options: this.distributionChartOptions
      };
    },
    carbonIntensity(isPrev = false) {
      const id = isPrev ? 'asset-report-emissions-prev' : 'asset-report-emissions';

      if (!this.analytics[id]?.results?.accountBreakdown) return null;

      const { results } = this.analytics[id];

      const resultsWithFloorArea = results.accountBreakdown.filter(item => item.account.floorArea && Number(item.account.floorArea) > 1);

      const intensities = {};

      intensities.landlord = resultsWithFloorArea
        .filter(item => item.meterUser === 'landlord')
        .reduce((sum, item) => {
          const floorArea = floorAreaConversion(item.account.floorArea, item.account.floorAreaUnit);

          return sum + item.total / floorArea;
        }, 0);

      intensities.tenant = resultsWithFloorArea
        .filter(item => item.meterUser === 'tenant')
        .reduce((sum, item) => {
          const floorArea = floorAreaConversion(item.account.floorArea, item.account.floorAreaUnit);

          return sum + item.total / floorArea;
        }, 0);

      intensities.total = intensities.landlord + intensities.tenant;

      return intensities;
    }
  }
};
</script>
