<template>
  <div>
    <Page>
      <h2>Projects Summary</h2>
      <p>
        The latest updates from any projects being undertaken or completed at this asset between {{ period.startDate | date('Do MMMM YYYY') }} and
        {{ period.endDate | date('Do MMMM YYYY') }}.
      </p>
      <div class="row mb-5">
        <div class="col-4">
          <ConsumptionCard
            v-if="projects()"
            :value="projects().filter(p => p.status === 'pending').length"
            :prev-value="projects(true).filter(p => p.status === 'pending').length"
            precision="0"
            icon-class="fa-ruler-triangle"
            description="Pending"
            units="Projects"
            comparison-period="period"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['projects']"
            :prev-value-loading="loadingAction.getAnalytics['projects-prev']"
            :error="errorAction.getAnalytics['projects']"
            is-report
            inverse-color
          />
        </div>
        <div class="col-4">
          <ConsumptionCard
            v-if="projects()"
            :value="projects().filter(p => p.status === 'active').length"
            :prev-value="projects(true).filter(p => p.status === 'active').length"
            precision="0"
            icon-class="fa-traffic-cone"
            description="Active"
            units="Projects"
            comparison-period="period"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['projects']"
            :prev-value-loading="loadingAction.getAnalytics['projects-prev']"
            :error="errorAction.getAnalytics['projects']"
            is-report
            inverse-color
          />
        </div>
        <div class="col-4">
          <ConsumptionCard
            v-if="projects()"
            :value="projects().filter(p => p.status === 'completed').length"
            :prev-value="projects(true).filter(p => p.status === 'completed').length"
            precision="0"
            icon-class="fa-check"
            description="Completed"
            units="Projects"
            comparison-period="period"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['projects']"
            :prev-value-loading="loadingAction.getAnalytics['projects-prev']"
            :error="errorAction.getAnalytics['projects']"
            is-report
            inverse-color
          />
        </div>
      </div>
      <h3>List of projects</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Tasks Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects().slice(0, projectsFirstPage)" :key="project._id">
            <td>
              <div class="mb-1 font-w600">{{ project.name }}</div>
              <div>{{ project.description.length > 100 ? project.description.trim().slice(0, 100) + '...' : project.description }}</div>
            </td>
            <td>
              <span
                :class="{
                  'text-success': project.status === 'completed',
                  'text-warning': project.status === 'active',
                  'text-muted': project.status === 'pending'
                }"
                class="font-w600"
                >{{ project.status | capitalize }}</span
              >
            </td>
            <td>{{ project.startDate | date }}</td>
            <td>
              <span v-if="project.isOverdue" class="text-danger">
                {{ project.endDate | date }}
              </span>
              <span v-else>
                {{ project.endDate | date }}
              </span>
            </td>
            <td class="text-right font-w600" style="width: 200px">
              <span v-if="project.tasks.every(t => t.status === 'completed')" class="text-success"> 100% <i class="fa fa-circle-check"></i> </span>
              <span v-else class="text-muted">
                {{ ((project.tasks.filter(t => t.status === 'completed').length / project.tasks.length) * 100) | numberFormat }}% ({{
                  project.tasks.filter(t => t.status !== 'completed').length
                }}
                incomplete)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Page>
    <Page v-for="(page, index) in projectPages" :key="index">
      <h3>List of projects cont.</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Tasks Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in page" :key="project._id">
            <td>
              <div class="mb-1 font-w600">{{ project.name }}</div>
              <div>{{ project.description.length > 100 ? project.description.trim().slice(0, 100) + '...' : project.description }}</div>
            </td>
            <td>
              <span
                :class="{
                  'text-success': project.status === 'completed',
                  'text-warning': project.status === 'active',
                  'text-muted': project.status === 'pending'
                }"
                class="font-w600"
                >{{ project.status | capitalize }}</span
              >
            </td>
            <td>{{ project.startDate | date }}</td>
            <td>
              <span v-if="project.isOverdue" class="text-danger">
                {{ project.endDate | date }}
              </span>
              <span v-else>
                {{ project.endDate | date }}
              </span>
            </td>
            <td class="text-right font-w600" style="width: 200px">
              <span v-if="project.tasks.every(t => t.status === 'completed')" class="text-success"> 100% <i class="fa fa-circle-check"></i> </span>
              <span v-else class="text-muted">
                {{ ((project.tasks.filter(t => t.status === 'completed').length / project.tasks.length) * 100) | numberFormat }}% ({{
                  project.tasks.filter(t => t.status !== 'completed').length
                }}
                incomplete)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Page>
  </div>
</template>
<script>
import moment from 'moment';

import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import Page from './Page';

export default {
  name: 'ProjectPages',
  components: {
    ConsumptionCard,
    Page
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Object,
      required: true
    },
    errorAction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      projectPages: [],
      projectsFirstPage: 7,
      projectsPerPage: 10
    };
  },
  methods: {
    projects(isPrev = false) {
      const id = isPrev ? 'projects-prev' : 'projects';

      if (!this.analytics[id]?.results) {
        return [];
      }

      const { results } = this.analytics[id];

      const projects = results.map(project => {
        const isOverdue = project.status !== 'completed' && moment().isAfter(project.endDate);

        return {
          isOverdue,
          ...project
        };
      });

      projects.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      if (projects.length > this.projectsFirstPage) {
        // Add more pages to report
        const additionalProjects = projects.slice(this.projectsFirstPage); // 15 projects on first page

        this.projectPages = additionalProjects.reduce((acc, item, index) => {
          if (index % this.projectsPerPage === 0) acc.push(additionalProjects.slice(index, index + this.projectsPerPage));
          return acc;
        }, []);
      }

      return projects;
    }
  }
};
</script>
