<template>
  <div>
    <div class="mb-5">
      <p>
        {{ type | capitalize }} consumption for the period {{ period.startDate.format('Do MMM YYYY') }} to {{ period.endDate.format('Do MMM YYYY') }}
      </p>
      <apexchart v-if="consumptionChart" height="300" :options="consumptionChart.options" :series="consumptionChart.series" />
    </div>
    <div class="row">
      <div class="col-6 pr-4">
        <h3>Landlord vs Tenant</h3>
        <apexchart v-if="tenantChart" height="100" :options="tenantChart.summaryOptions" :series="tenantChart.summarySeries" />
        <apexchart v-if="tenantChart" height="250" :options="tenantChart.options" :series="tenantChart.series" />
      </div>
      <div class="col-6 pl-4">
        <h3>Actual vs Estimated</h3>
        <apexchart v-if="estimateChart" height="100" :options="estimateChart.summaryOptions" :series="estimateChart.summarySeries" />
        <apexchart v-if="estimateChart" height="250" :options="estimateChart.options" :series="estimateChart.series" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'ConsumptionPage',
  props: {
    asset: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    analytics: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Object,
      required: true
    },
    errorAction: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    commonChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        colors: this.chartOptions.colors,
        legend: {
          fontSize: '18px',
          fontWeight: 600,
          itemMargin: {
            horizontal: 12
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          },
          title: {
            text: `Consumption (${this.units})`,
            offsetX: -10,
            style: {
              fontSize: '20px',
              fontWeight: 600
            }
          }
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        }
      };
    },
    commonSmallChartOptions() {
      return {
        ...this.commonChartOptions,
        legend: {
          ...this.commonChartOptions.legend,
          offsetY: 10,
          show: false
        },
        colors: [this.commonChartOptions.colors[1], this.commonChartOptions.colors[0]],
        xaxis: {
          ...this.commonChartOptions.xaxis,

          tickPlacement: 'on',
          // tickAmount: Math.ceil(categories.length / 2),
          labels: {
            ...this.commonChartOptions.xaxis.labels,
            offsetY: 8,
            style: {
              ...this.commonChartOptions.xaxis.labels.style,
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          ...this.commonChartOptions.yaxis,
          title: {
            text: `Consumption (${this.units})`,
            offsetX: -5,
            style: {
              fontSize: '16px',
              fontWeight: 600
            }
          },
          labels: {
            ...this.commonChartOptions.yaxis.labels,
            style: {
              ...this.commonChartOptions.yaxis.labels.style,
              fontSize: '14px'
            }
          }
        }
      };
    },
    summaryChartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        colors: this.commonSmallChartOptions.colors,
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: ['Total'],
          title: false,
          labels: {
            show: false
          },
          axisTicks: false,
          axisBorder: false
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          ...this.commonSmallChartOptions.legend,
          show: true,
          position: 'top'
        },
        stroke: {
          show: false
        },
        dataLabels: {
          style: {
            fontWeight: 600
          }
        }
      };
    },
    consumptionChart() {
      // const accountTypes = ['electricity', 'gas', 'water'];

      const monthlyConsumption = this.analytics[`${this.type}-consumption`]?.results?.monthlyConsumption;

      if (!monthlyConsumption) return null;

      const series = [
        {
          name: 'Prev Period',
          data: (this.analytics[`${this.type}-consumption-prev`]?.results?.monthlyConsumption || []).map(c => c.consumption.toFixed(0))
        },
        {
          name: 'Current Period',
          data: (this.analytics[`${this.type}-consumption`]?.results?.monthlyConsumption || []).map(c => c.consumption.toFixed(0))
        }
      ];

      return {
        series,
        options: {
          ...this.commonChartOptions,
          xaxis: {
            ...this.commonChartOptions.xaxis,
            categories: (monthlyConsumption || []).map(p => moment(p.period, 'YYYY-MM').format('MMM YY'))
          }
        }
      };
    },
    tenantChart() {
      // const accountTypes = ['electricity', 'gas', 'water'];

      const { monthlyConsumption, tenantConsumption, totalConsumption } = this.analytics[`${this.type}-consumption`]?.results || {};

      if (!monthlyConsumption) return null;

      const series = [
        {
          name: 'Landlord',
          data: (monthlyConsumption || []).map(c => (c.consumption - c.tenantConsumption).toFixed(0))
        },
        {
          name: 'Tenant',
          data: (monthlyConsumption || []).map(c => c.tenantConsumption.toFixed(0))
        }
      ];

      const categories = (monthlyConsumption || []).map(p => moment(p.period, 'YYYY-MM').format('MMM YY'));

      return {
        series,
        options: {
          ...this.commonSmallChartOptions,
          xaxis: {
            ...this.commonSmallChartOptions.xaxis,
            categories
          }
        },
        summarySeries: [
          {
            name: 'Landlord',
            data: [(totalConsumption - tenantConsumption).toFixed(2)]
          },
          {
            name: 'Tenant',
            data: [tenantConsumption.toFixed(2)]
          }
        ],
        summaryOptions: {
          ...this.summaryChartOptions
        }
      };
    },
    estimateChart() {
      // const accountTypes = ['electricity', 'gas', 'water'];

      const { monthlyConsumption, tenantConsumption, totalConsumption } = this.analytics[`${this.type}-consumption`]?.results || {};

      if (!monthlyConsumption) return null;

      const series = [
        {
          name: 'Actual',
          data: (this.analytics[`${this.type}-consumption`]?.results?.monthlyConsumption || []).map(c =>
            (c.consumption - c.estimatedConsumption).toFixed(0)
          )
        },
        {
          name: 'Estimated',
          data: (this.analytics[`${this.type}-consumption`]?.results?.monthlyConsumption || []).map(c => c.estimatedConsumption.toFixed(0))
        }
      ];

      const categories = (monthlyConsumption || []).map(p => moment(p.period, 'YYYY-MM').format('MMM YY'));

      return {
        series,
        options: {
          ...this.commonSmallChartOptions,
          xaxis: {
            ...this.commonSmallChartOptions.xaxis,
            categories
          },
          yaxis: {
            ...this.commonSmallChartOptions.yaxis,
            title: {
              show: false
            }
          }
        },
        summarySeries: [
          {
            name: 'Actual',
            data: [(totalConsumption - tenantConsumption).toFixed(2)]
          },
          {
            name: 'Estimated',
            data: [tenantConsumption.toFixed(2)]
          }
        ],
        summaryOptions: {
          ...this.summaryChartOptions
        }
      };
    }
  }
};
</script>
