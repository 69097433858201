var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('ConsumptionCard', {
    staticClass: "bg-secondary-outline flex-grow-1",
    attrs: {
      "value": _vm.emissionScopeBreakdown['1'] / 1000,
      "prev-value": _vm.emissionScopeBreakdown['1-prev'] / 1000,
      "precision": "2",
      "icon-class": "fa-flame",
      "description": "Scope 1",
      "units": "t CO2e",
      "comparison-period": _vm.options.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('ConsumptionCard', {
    staticClass: "bg-secondary-outline flex-grow-1",
    attrs: {
      "value": _vm.emissionScopeBreakdown['2'] / 1000,
      "prev-value": _vm.emissionScopeBreakdown['2-prev'] / 1000,
      "precision": "2",
      "icon-class": "fa-plug",
      "description": "Scope 2",
      "units": "t CO2e",
      "comparison-period": _vm.options.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('ConsumptionCard', {
    staticClass: "bg-secondary-outline flex-grow-1",
    attrs: {
      "value": _vm.emissionScopeBreakdown['3'] / 1000,
      "prev-value": _vm.emissionScopeBreakdown['3-prev'] / 1000,
      "precision": "2",
      "icon-class": "fa-conveyor-belt-boxes",
      "description": "Scope 3",
      "units": "t CO2e",
      "comparison-period": _vm.options.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  })], 1)]), _vm.emissionTypeBreakdown() ? _c('div', {
    staticClass: "row mb-4"
  }, _vm._l(_vm.types, function (type) {
    var _vm$emissionTypeBreak, _vm$emissionTypeBreak2;

    return _c('div', {
      key: type,
      class: "col-".concat(12 / _vm.types.length)
    }, [_c('ConsumptionCard', {
      staticClass: "bg-primary flex-grow-1",
      attrs: {
        "value": ((_vm$emissionTypeBreak = _vm.emissionTypeBreakdown()) === null || _vm$emissionTypeBreak === void 0 ? void 0 : _vm$emissionTypeBreak[type]) / 1000,
        "prev-value": ((_vm$emissionTypeBreak2 = _vm.emissionTypeBreakdown(true)) === null || _vm$emissionTypeBreak2 === void 0 ? void 0 : _vm$emissionTypeBreak2[type]) / 1000,
        "precision": "2",
        "icon-class": _vm.typeIcon(type),
        "description": _vm.capitalize(type),
        "units": "t CO2e",
        "comparison-period": _vm.options.comparePeriod,
        "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
        "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
        "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
        "is-report": ""
      }
    })], 1);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center mb-5"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1 bg-secondary",
    attrs: {
      "value": _vm.analytics['asset-report-emissions'] && _vm.analytics['asset-report-emissions'].results && _vm.analytics['asset-report-emissions'].results.total / 1000,
      "prev-value": _vm.analytics['asset-report-emissions-prev'] && _vm.analytics['asset-report-emissions-prev'].results && _vm.analytics['asset-report-emissions-prev'].results.total / 1000,
      "icon-class": "fa-leaf text-success",
      "description": "Total Emissions",
      "units": "t CO2e",
      "comparison-period": _vm.options.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  })], 1)]), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('ChartCard', {
    attrs: {
      "loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions']
    }
  }, [[_vm.analytics["asset-report-emissions"] && _vm.analytics["asset-report-emissions"].results ? _c('div', [_c('apexchart', {
    attrs: {
      "height": "350",
      "options": _vm.scopeBreakdownChart.options,
      "series": _vm.scopeBreakdownChart.series
    }
  }), _c('div', {
    staticClass: "mb-0 mt-4 text-muted"
  }, [_c('strong', [_vm._v("Source:")]), _vm._v(" " + _vm._s(_vm.emissionSources.join(', ')))])], 1) : _vm._e()]], 2)], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('ChartCard', {
    attrs: {
      "loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions']
    }
  }, [[_vm.analytics["asset-report-emissions"] && _vm.analytics["asset-report-emissions"].results ? _c('div', [_c('apexchart', {
    attrs: {
      "height": "350",
      "options": _vm.emissionsBreakdownChart.options,
      "series": _vm.emissionsBreakdownChart.series
    }
  }), _c('div', {
    staticClass: "mb-0 mt-4 text-muted"
  })], 1) : _vm._e()]], 2)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v("Scope Breakdowns")])]);
}]

export { render, staticRenderFns }