<template>
  <div class="ConsumptionCard bg-lighter rounded-md p-3" :class="{ 'ConsumptionCard--report': isReport }">
    <div v-if="error">
      <div class="text-center">
        <div class="font-w600">Error loading data.</div>
      </div>
    </div>

    <SpinnerLogo v-else-if="valueLoading" class="px-5" />

    <div v-else class="d-flex align-items-center">
      <div class="flex-grow-1">
        <div class="ConsumptionCard--description" :class="{ 'text-muted': !isReport }">{{ description }}</div>
        <div class="ConsumptionCard--header">
          <small v-if="prefix" :class="{ 'text-muted': !isReport }">{{ prefix }}</small> {{ formattedValue | numberFormat(precision) }}
          <small :class="{ 'text-muted': !isReport }" v-html="formattedUnits"></small>
        </div>
        <PctDiff
          v-if="!hidePrevValue"
          class="ConsumptionCard--delta"
          :value="value"
          :prev-value="prevValue"
          :prev-value-loading="prevValueLoading"
          :inverse="inverse || inverseColor"
          :comparison-period="comparisonPeriod"
          :is-report="isReport"
        />
        <small v-if="estimatedPercentage" :class="{ 'text-muted': !isReport }" class="ml-2"> |</small>
        <span v-if="estimatedPercentage" class="ConsumptionCard--estimated ml-1 pt-2" :class="{ 'text-muted': !isReport }">
          {{ estimatedPercentage.toFixed(2) }}% estimated</span
        >
      </div>
      <div v-if="!hideIcon" class="mx-3">
        <i class="fa fa-2x" :class="iconClass"></i>
      </div>
    </div>
  </div>
</template>
<script>
import PctDiff from '@/components/analytics/PctDiff';
import SpinnerLogo from '@/components/SpinnerLogo';

export default {
  name: 'ConsumptionCard',
  components: {
    PctDiff,
    SpinnerLogo
  },
  props: {
    value: {
      type: Number,
      default: 0,
      required: false
    },
    prevValue: {
      type: Number,
      default: null
    },
    description: {
      type: String,
      default: 'Electricity Usage'
    },
    units: {
      type: String,
      default: 'kWh'
    },
    precision: {
      type: [String, Number],
      default: 2
    },
    comparisonPeriod: {
      type: String,
      default: 'period'
    },
    estimatedPercentage: {
      type: Number,
      required: false,
      default: null
    },
    iconClass: {
      type: String,
      default: 'fa-bolt text-warning'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    valueLoading: {
      type: Boolean,
      default: true
    },
    prevValueLoading: {
      type: Boolean,
      default: true
    },
    error: {
      type: [String, Boolean],
      default: null
    },
    isReport: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    inverseColor: {
      type: Boolean,
      default: false
    },
    hidePrevValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedUnits() {
      let units = this.units;

      units = units.replace('CO2e', 'CO<sub>2</sub>e');

      units = units.replace('m3', 'm<sup>3</sup>');

      units = units.replace('m2', 'm<sup>2</sup>');

      return units;
    },
    formattedValue() {
      return this.value;
    }
  }
};
</script>
<style lang="scss">
.ConsumptionCard {
  &--header {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 4px;

    small {
      font-size: 65%;
    }
  }

  &--description {
    font-weight: 600;
    font-size: 0.9rem;
  }

  &--delta {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
  }

  &--estimated {
    font-style: italic;
    font-weight: 600;
    font-size: 12px;
  }
}

.ConsumptionCard--report {
  color: #fff;
  padding: 20px;
  background: #65c198;
  border-radius: 4px;

  .text-success {
    color: #c9f896 !important;
  }

  &.ConsumptionCard--dark {
    background: #27394a;
  }

  .ConsumptionCard--header {
    font-size: 30px;
  }
  .ConsumptionCard--description {
    font-size: 20px;
  }
  .ConsumptionCard--delta {
    font-size: 14px;
  }
}
</style>
